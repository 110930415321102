.top-bar-and-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: var(--white);
  background: rgba(0, 0, 0, 0.5);
}

.top-bar-and-footer.top-bar-and-footer__bottom-gray {
  background: var(--dark-gray);
}

.top-bar-and-footer__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: var(--dark-gray);
}

.top-bar-and-footer__number-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  gap: 5px;
  cursor: pointer;
  font-size: 18px;
}

.top-bar-and-footer__number-text {
  text-decoration: underline;
}

.top-bar-and-footer__link {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /*background-color: var(--dark-gray);*/
}

.top-bar-and-footer__image {
  height: auto;
  max-width: 236px;
  object-fit: contain;
}

@media screen and (min-width: 768px) {
  .top-bar-and-footer {
    background: rgba(0, 0, 0, 0.5);
  }

  .top-bar-and-footer__container {
    justify-content: space-between;
    flex-direction: row;
    background-color: transparent;
    padding: 0;
    height: 80px;
  }

  .top-bar-and-footer__number-container {
    justify-content: center;
    margin-top: 0;
    width: auto;
    height: auto;
    padding: 15px;
    font-size: 20px;
  }

  .top-bar-and-footer__link {
    width: calc(100vw * 0.35);
  }
}

@media screen and (min-width: 1200px) {
  .top-bar-and-footer__link {
    width: calc(100vw * 0.2);
  }
}
