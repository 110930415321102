.disclaimer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--dim-gray);
}

.disclaimer__menu {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1em;
  margin-bottom: 1em;
}

.disclaimer__menu-options {
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-size: 14px;
}

.disclaimer__container .disclaimer__button-as-link{
  color: #FFF;
  font-family: sans-serif;
  font-weight: 500;
}

.disclaimer__menu-text {
  color: #576566;
  float: left;
  font-family: sans-serif;
  font-family: Montserrat,sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 2em;
  margin-top: 0;
  text-align: justify;
}

.disclaimer__copyright {
  color: #576566;
    display: inline-block;
    font-family: sans-serif;
    font-family: Montserrat,sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-right: 0;
}

.disclaimer__copyright-hidden {
  display: none;
}

.disclaimer__button-as-link {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  color: #003a6e;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  transition: color 0.3s;
}

@media screen and (min-width: 768px) {
  .custom-submit_button-as-link .disclaimer__button-as-link {
    color: #003a6e;
    font-weight: 700;
  }

  .disclaimer__copyright {
    display: none;
    font-size: 11px;
  }

  .disclaimer__menu {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70vw;
    margin-top: 0.5em;
  }

  .disclaimer__copyright-hidden {
    display: flex;
    order: 1;
    flex-basis: 43%;
    margin: 0;
  }

  .disclaimer__menu-options {
    order: 2;
    flex-basis: 53%;
    flex-direction: row;
    font-size: 9px;
    justify-content: end;
  }

  .disclaimer__menu-text {
    order: 3;
    flex-basis: 100%;
  }

  .disclaimer__menu-options > *:not(:last-child)::after {
    content: '|';
    margin-left: 8px;
    vertical-align: middle;
  }
}

@media screen and (min-width: 1024px) {
  .disclaimer__copyright {
    font-size: 14px;
    flex-basis: 38%;
  }

  .disclaimer__menu-options {
    font-size: 14px;
    flex-basis: 59%;
  }
}

@media screen and (min-width: 1200px) {
  .disclaimer__copyright {
    font-size: 16px;
  }

  .disclaimer__copyright-hidden {
    flex-basis: 45%;
  }

  .disclaimer__menu-options {
    font-size: 16px;
    flex-basis: 52%;
  }
}
