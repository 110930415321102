.custom-submit__container {
  text-align: center;
  margin-top: 1.2rem;
  /*word-spacing: 0.35rem;*/
}

.custom-submit__consent-text {
  font-size: 10px;
  font-weight: 400;
  line-height: initial;
  color: #003a6e;
  max-width: 320px;
  width: 90%;
}

.custom-submit__btn {
  width: 100%;
  background-color: rgba(69,191,124,1);
  margin: 0.6rem auto;
  text-align: center;
  color: var(--white);
  font-weight: 600;
  font-size: 20px;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  padding: 1rem;
  cursor: pointer;
  width: 94%;
  max-width: 350px;
  display: block;
}

.custom-submit__btn + .custom-submit__consent-text{
  max-width: 650px;
  width: 90%;
}

.custom-submit__btn + .custom-submit__consent-text,
.custom-submit__btn + .custom-submit__consent-text a {
  font-size: 10px;
  color: #003a6e;
  text-decoration: none;
}

.custom-submit__button-as-link {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
  outline: none;
  transition: color 0.3s;
}


@media(max-width: 767px){

.custom-submit__btn {
    font-size: 17px;
  }
}