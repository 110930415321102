

.content {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-clip: border-box;
  background-attachment: fixed;
  /*scroll on mobile*/
  /*background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("https://static.pexels.com/photos/380769/pexels-photo-380769.jpeg");*/
  color: #fff;
  padding: 0;
  text-align: center;
}

  /* Accordion
  ================================================== */
  .acc {
    margin: 0 auto;
    max-width: 1050px;
  }

  .acc__card,
  .accordion-item {
    margin: 0;
    position: relative;
    border-bottom: 1px solid #012638 !important;
    border: 0 none;
    background-color: transparent;
    border-radius: 0 !important;
  }

  .accordion {
    background-color: transparent;
    border: 0 none;
  }
  .acc__title,
  .accordion-header,
  .accordion-button {
    background-color: transparent !important;
    border: 0px none; 
    cursor: pointer;
    display: block;
    padding: 0em;
    position: relative;
    text-align: left;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    color: #012638;
  }
  .accordion-button {
    background-color: transparent !important;
    border: 0px none;
    border-radius: 0 !important;
    padding: 0.8em;
  }
  .acc__title:after,
  .accordion-button:after {
    width: 8px;
    height: 8px;
    border-left: 1px solid #012638;
    border-bottom: 1px solid #012638;
    position: absolute;
    right: 50px;
    content: " ";
    top: 30px;
    -webkit-transform: rotate(-45deg) !important;
    transform: rotate(-45deg) !important;
    transition: all 0.2s ease-in-out;
    border-radius: 0 !important;
    background-image: none !important;
  }
  .acc__title.active:after,
  .accordion-button:not(.collapsed):after {
    -webkit-transform: rotate(135deg) !important;
    transform: rotate(135deg) !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    top: 35px;
  }
  .acc__card .acc__title:hover,
  .acc__card .acc__title.active,
  .accordion-item .accordion-header button:hover,
  .accordion-item .accordion-header button:not(.collapsed),
  .accordion-button:hover,
  .accordion-button button:not(.collapsed){
    background-color: rgba(1, 38, 56, .1) !important;
  }

  .accordion-button:focus,
  .accordion-button:active {
    outline: 0 none;
    box-shadow: 0 0 0;
  }
  .acc__card .acc__title.active,
  .accordion-item .accordion-header button:not(.collapsed) {
    margin-bottom: 0.7em;
    border-radius: 0 !important;
  }

  .acc__panel,
  .accordion-body {
    color: #012638;
    /*display: none;*/
    margin: 0;
    padding: 0 1.5em 1em;
    text-align: left;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 300 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
  }

  .acc__panel ol,
  .acc__panel ul {
    padding: 0 1em;}
    .acc__panel ol {
      list-style-type: decimal;
    }
    .acc__panel ul {
      list-style-type: disc;
    }
    .acc__panel ol li,
    .acc__panel ul li {
      font-weight: 300 !important;
      margin: 15px 0;
    }
    .acc__panel ol li {
      list-style-type: decimal;
    }
    .acc__panel ul li {
      list-style-type: disc;
    }
    .acc__panel p + p {
      margin-top: 20px;
    }

    @media(max-width: 600px){
      .acc__title:after,
      .accordion-header button:after {
        right: 20px;
        top: 15px;
      }
      .acc__title.active:after,
      .accordion-header button:not(.collapsed):after {
        top: 20px;
      }
      .acc__title,
      .accordion-header button {
        font-size: 16px;
        line-height: 20px;
        /* max-width: 80%; */
        padding-right: 3em;
      }
      .acc__panel,
      .accordion-body {
        font-size: 14px;
      }
    }
