.disclaimer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--black);
}

.content-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #075f8a;
  padding: 0.8rem;
}

.content-modal__header h2 {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.content-modal__title {
  color: var(--white);
  font-size: 1.5rem;
}

.content-modal__close-btn-icon {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: var(--white);
}

.content-modal__close-btn {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: var(--dim-gray);
}

.content-modal__body {
  text-align: justify;
  padding: 2rem;
}

.content-modal__footer {
  display: flex;
  margin-bottom: 1rem;
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  padding: 1rem;
}

.disclaimer__menu {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1em;
  margin-bottom: 1em;
}
.disclaimer__menu-options {
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-size: 14px;
}
.disclaimer__menu-text {
  color: #000;
  float: left;
  font-family: sans-serif;
  font-family: Montserrat,sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 2em;
  margin-top: 0;
  text-align: justify;
}

.disclaimer__copyright {
  color: #000;
  color: var(--white);
  display: inline-block;
  font-family: sans-serif;
  font-family: Montserrat,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 0;
}

.disclaimer__copyright-hidden {
  display: none;
}
.disclaimer__button-as-link {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
  outline: none;
  transition: color 0.3s;
}

.content-modal__body {
  padding: 20px;
  color: #000;
  font-family: 'Montserrat', sans-serif;
}

.inner .content-modal__body ul {
  list-style-type: circle;
  font-size: 14px;
  line-height: 1.5em;
  color: var(--slate-gray);
  margin-bottom: 10px;
  font-weight: 400;
  display: block !important;
  text-align: left !important;;
  margin-left: 1em !important;
}

.content-modal__body li {
  margin: 5px;
}

.content-modal__body {
  text-align: left;
  background-color: var(--black);
  padding: 1.2em;
}

.content-modal__body h1 {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--black);
}

.content-modal__body h2 {
  font-size: 1.53125rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2em;
  color: var(--black);
}

.content-modal__body h3 {
  font-size: 1.3125rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2em;
  color: var(--black);
}

.content-modal__body > h4:nth-of-type(n+2) {
  margin-top: 1.5rem;
}

.content-modal__body h4 {
  font-size: 1.09375rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2em;
  color: var(--black);
}

form#leadform .content-modal__body p {
  font-size: 14px;
  line-height: 1.5em;
  color: var(--slate-gray);
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 500;
}

.content-modal__body table,
th,
td {
  border: 1px solid #000;
  border-collapse: collapse;
  font-size: 12px;
  text-align: left;
  padding: 5px;
  color: var(--slate-gray);
  line-height: 1.4em;
  margin-bottom: 10px;
  font-weight: 400;
}

.content-modal__body th {
  color: var(--slate-gray);
  font-weight: 700;
}

.content-modal__body ul li {
  list-style-type: disc;
  flex: none;
}

.content-modal__body ol {
  font-size: 14px;
  line-height: 1.5em;
  color: var(--slate-gray);
  margin-bottom: 10px;
  font-weight: 400;
}


@media screen and (min-width: 768px) {
  .disclaimer__container {
    color: var(--black);
    font-family: sans-serif;
  }

  .disclaimer__button-as-link {
    color: var(--white);
    font-weight: bold;
  }

  .disclaimer__copyright {
    display: none;
    font-size: 11px;
  }
  .disclaimer__menu {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70vw;
    margin-top: 0.5em;
  }
  .disclaimer__copyright-hidden {
    display: flex;
    order: 1;
    flex-basis: 43%;
    margin: 0;
  }
  .disclaimer__menu-options {
    order: 2;
    flex-basis: 53%;
    flex-direction: row;
    font-size: 9px;
    justify-content: end;
  }
  .disclaimer__menu-text {
    order: 3;
    flex-basis: 100%;
  }
  .disclaimer__menu-options > *:not(:last-child)::after {
    content: '|';
    margin-left: 8px;
    vertical-align: middle;
  }
  .content-modal__body {
    padding: 1em !important;
  }
}

@media screen and (min-width: 1024px) {
  .disclaimer__copyright {
    font-size: 14px;
    flex-basis: 38%;
  }
  .disclaimer__menu-options {
    font-size: 14px;
    flex-basis: 59%;
  }
  .content-modal__body {
    text-align: left;
    background-color: var(--black);
    padding: 1.2em;
  }
}
@media screen and (min-width: 1200px) {
  .disclaimer__copyright {
    font-size: 16px;
  }
  .disclaimer__copyright-hidden {
    flex-basis: 45%;
  }
  .disclaimer__menu-options {
    font-size: 16px;
    flex-basis: 52%;
  }
}