  
img {max-width: 100%;}

.slick-slider .slick-slide {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}

.slick-slider .slick-slide img {
  max-width: 100%;
} 

.slide-button {
  cursor: pointer
}

.slide-button.active-text p span {
  color: #000000 !important;
  font-family: 'Roboto' !important;
}

.slider-floor .slick-slide img,
section.clientArea .carousel .carousel-inner .carousel-item > div > img,
section.clientArea .slick-slider .slick-slide > div > div > img {
  max-width: 300px;
  margin: auto;
  display: block;
  border-radius: 300px;
}

.slick-list .slick-track {
  display: flex;
}

div[class*="slider-"] .slick-next,
div[class*="slider-"] .slick-prev {
  top: 50%;
  bottom: auto;
  width: 14px;
  height: 28px;
  position: absolute;
  background: transparent;
  border: 0 none;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
  font-size: 0;
}

div[class*="slider-"] .slick-prev {
  left: -40px;
}

div[class*="slider-"] .slick-next {
  right: -40px;
  left: auto;
}

.slick-prev:before,
.slick-next:before{
  content: '' !important;
  background-image: url(https://user-assets-unbounce-com.s3.amazonaws.com/b3a19027-5686-4a97-bff9-aebe47bbdfd6/ed0f32e4-7d57-403e-ae0b-707082df27bc/vector-35-2x.original.png);
  width: 14px;
  height: 28px;
  position: absolute;
  opacity: 1 !important;
  font-size:0;
  left:0;
  top:0;
  background-position: 0 0;
  cursor: pointer;
  background-size: 100% auto;
}
.slick-prev:before{
  -webkit-transform:scaleX(-1);
  transform:scaleX(-1);
}

ul.slick-dots {
  position: absolute;
  top: 100%;
  width: 100%;
  text-align: center;
  padding: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
ul.slick-dots li {
  display: inline-block;
  margin: 0 30px;
}
ul.slick-dots li button {
  font-size: 0;
  width: 10px;
  height: 10px;
  border: 0 none;
  border-radius: 50px;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border: 1px solid #C1C1C1;
}
ul.slick-dots li.slick-active button {
  background-color: #C1C1C1;
}

ul.slick-dots li button:before{
  display: none;
}

.slick-disabled {
  opacity: 0.5;
}

div.slider-floor .slider-box,
section.clientArea .carousel .carousel-inner .carousel-item,
section.clientArea .slick-slider .slick-slide > div {
  display: flex !important;
  align-items: center;
}
div.slider-floor .slider-box div,
section.clientArea .carousel .carousel-inner .carousel-item div div,
section.clientArea .slick-slider .slick-slide > div div {
  flex: 1;
}
div.slider-floor .slider-box div + div,
section.clientArea .carousel .carousel-inner .carousel-item div + div,
section.clientArea .slick-slider .slick-slide > div div + div {
  flex: 2;
  padding-left: 20px;
}

div.slider-floor .slider-box h2:before,
section.clientArea .carousel .carousel-inner .carousel-item div h2:before,
section.clientArea .slick-slider .slick-slide > div div h2:before {
  content: "“";
  position: absolute;
  top: -55px;
  color: rgba(17, 52, 71, 0.13);
  font-size: 200px;
  line-height: 1;
  z-index: 0;
  left: -30px;
}
div.slider-reviews .slick-slide,
section#testimonials .carousel .carousel-item,
section#testimonials .slick-slider .slick-slide {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, .2);
  margin: 0.3em 1em;
  padding: 1em 1em 0;
  min-height: 250px;
  box-sizing: border-box;
  border-radius: 10px;
}
section#testimonials .slick-slider {
  margin-bottom: 2em;  
}
section#testimonials .slick-slider .slick-list {
  height: auto !important;  
}
section#testimonials .slick-slider .slick-slide {
  margin-top: 1.5em;
  margin-bottom: 1.8em;
}
div.slider-floor .slider-box h2,
div.slider-reviews .slider-box h2,
section#testimonials .carousel h2,
section.clientArea .carousel h2,
section.clientArea .slick-slider .slick-slide > div h2{
  font-size: 25px;
  line-height: 1.3;
  color: #113447;
  font-weight: 700;
  position: relative;
  font-family: 'Poppins';
  text-align: left;
}
div.slider-floor .slider-box h3,
div.slider-reviews .slider-box h3,
section#testimonials .carousel h3,
section#testimonials .slick-slider .slick-slide h3,
section.clientArea .carousel h3,
section.clientArea .slick-slider .slick-slide > div h3{
  font-size: 20px;
  margin: 5px 0 20px;
  color: #113447;
  font-weight: 500;
  font-family: 'Poppins';
  text-align: left;
}
div.slider-reviews .slider-box h3,
section#testimonials .carousel h3,
section#testimonials .slick-slider .slick-slide > div h3{
  margin-top: 1em;
  text-align: left;
}

div.slider-floor .slider-box p,
div.slider-reviews .slider-box p,
section#testimonials .carousel p,
section.clientArea .carousel p,
section.clientArea .slick-slider .slick-slide > div p{
  font-size: 18px;
  line-height: 1.3;
  color: #113447;
  font-weight: 400;
  font-family: 'Poppins';
  text-align: left;
}
div.slider-reviews .slider-box p,
section#testimonials .carousel p {
  padding: 0.5em;
  text-align: left;
}

/*section#testimonials .slick-slider .slick-slide > div p {
  min-height: 140px;
}
*/
section#testimonials .carousel .carousel-inner,
section.clientArea .carousel .carousel-inner,
section.clientArea .slick-slider .slick-slide  {
  padding: 15px 0 30px;
}

div.slider-floor ul.slick-dots,
div.slider-reviews ul.slick-dots,
section#testimonials .carousel .carousel-indicators,
section#testimonials .slick-dots {
  top: auto;
  bottom: -25px;
  z-index: 999;
}
div.slider-floor ul.slick-dots,
section#testimonials .carousel .carousel-indicators{
  bottom: -20px;
}
section#testimonials .carousel .carousel-indicators{
  bottom: 0px;
}
div.slider-floor ul.slick-dots li,
div.slider-reviews ul.slick-dots li,
section.clientArea ul.slick-dots li,
section#testimonials ul.slick-dots li,
section#testimonials .carousel .carousel-indicators li {
  margin: 0 5px;
}
div.slider-floor ul.slick-dots li button,
div.slider-reviews ul.slick-dots li button,
section.clientArea ul.slick-dots li button,
section#testimonials ul.slick-dots li button,
section#testimonials .carousel .carousel-indicators:before,
section#testimonials .carousel .carousel-indicators:before{
  border-color: #113447;
  width: 15px;
  height: 16px;
}
div.slider-floor ul.slick-dots li.slick-active button,
div.slider-reviews ul.slick-dots li.slick-active button,
section#testimonials ul.slick-dots li.slick-active button,
section.clientArea ul.slick-dots li.slick-active button,
section#testimonials .carousel .carousel-indicators:before{
  background-color: #113447;
}

@media(max-width: 767px){
  div[class*="slider-"] .slick-next,
  div[class*="slider-"] .slick-prev,
  .slick-prev:before,
  .slick-next:before{
    width: 30px;
    height: 30px;
  }
  div[class*="slider-"] .slick-next,
  div[class*="slider-"] .slick-prev {
    top: 65%;
  }
  ul.slick-dots li {
    display: inline-block;
    margin: 0 5px;
  }
  ul.slick-dots li button {
    width: 10px;
    height: 10px;
  }
  .slick-prev:before,
  .slick-next:before{
    width: 32px;
    height: 32px;
  }
  div.slider-floor ul.slick-dots {
    bottom: 0;
    height: 42px;
    padding-top: 10px;
    /*display: none !important;*/
  }
  div[class*="slider-"] .slick-next,
  div[class*="slider-"] .slick-prev {
    top: 74%;
  }

  .slick-prev:before,
  .slick-next:before{
    background-image: url("https://user-assets-unbounce-com.s3.amazonaws.com/b3a19027-5686-4a97-bff9-aebe47bbdfd6/84fed9db-90dd-4aae-abf6-cc243fc15fa9/carbon-chevron-right.original.png");
  }

  .slider-floor .slick-list .slick-slide,
  .slider-floor1 .slick-list .slick-slide {
    margin-left: 0;
    margin-right: 0;
  }
  
  div.slider-floor .slider-box,
  section.clientArea .slick-slider .slick-slide > div {
    flex-direction: column;
  }
  div.slider-floor .slider-box p,
  section.clientArea .slick-slider .slick-slide > div p
  div.slider-reviews .slider-box p{
    font-size: 15px;
  }
  div.slider-floor .slider-box div + div,
  section.clientArea .slick-slider .slick-slide > div div + div {
    /*padding-left: 0;*/
    padding-top: 30px;
  }
  div.slider-reviews .slick-slide {
    min-height: 290px;
  }
  div.slider-reviews ul.slick-dots {
    bottom: auto;
    margin-top: 20px;
  }

}
