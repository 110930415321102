
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap');

* { /** place holder for font smoothing**/
	text-rendering:;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color:;
	-webkit-overflow-scrolling: touch;
	-webkit-scroll-behavior: smooth;
	scroll-behavior: smooth;
}

.font-reg{
	font-weight: 400;
}
.font-med{
	font-weight: 500;
}
.font-bold{
	font-weight: 600;
}
.font-xbold{
	font-weight: 700;
}

img[src*="version"] {
	display: none;
}

body {
	font-family: 'Poppins', sans-serif;
	overflow-x: hidden;
}

.text-green {
	color: rgb(69, 191, 124);
}

.text-blue {
	color: rgb(1, 38, 56);
}


.boxShadow {
	background-color: #FFF !important;
	-webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, .2);
	box-shadow: 5px 5px 10px rgba(0, 0, 0, .2);
}


.ctaGreen,
.ctaGreen:hover {
	text-shadow: none;
	color: #fff;
	font-size: 18px;
	line-height: 22px;
	font-weight: 600;
	font-style: normal;
	font-family: 'Poppins', sans-serif;
	text-align: center;
	background-color: rgba(69,191,124,1);
	padding: 1.2em 2em;
	-webkit-border-radius: 40px;
	border-radius: 40px;
	text-decoration: none;
	transition: 0.5s all;
	-webkit-transition: 0.5s all;
	border: 0 none;
}

.ctaGreen.ctaSml {
	padding: 1em;
	font-size: 16px;
	line-height: 19px;
}

.ctaGreen:hover,
.ctaGreen.ctaSml:hover {
	background-color: rgba(52,181,105,1);
}

section.heroArea:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.44);
	content: "";
}

section.heroArea {
	background-image:url(../../assets/img/heroBg.jpg);
	background-size: cover;
	background-position: center center;
	position: relative;
}

section.heroArea h1 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-size: 45px;
	line-height: 1.15;
}

section.heroArea h2 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-size: 33px;
	line-height: 1;
}

section.heroArea form#form {
	background-color: #FFF;
	max-width: 800px;
	-webkit-border-radius: 100px;
	border-radius: 100px;
	padding: 0.8em 1em;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

section.heroArea form#form input[type="text"],
section.heroArea form#form input[type="text"]:focus,
section.heroArea form#form input[type="text"]:active,
section.heroArea form#form input#address,
section.heroArea form#form input#address:focus,
section.heroArea form#form input#address:active {
	font-family: 'Poppins', sans-serif;
	border: 0 none;
	width: 100%;
	max-width: 411px;
	font-size: 16px;
	line-height: 16px;
	padding-left: 16px;
	padding-right: 16px;
	outline: 0 none;
}

section.heroArea form#form input[type="submit"],
section.heroArea form#form input[type="submit"]:focus,
section.heroArea form#form input[type="submit"]:active {
	cursor: pointer;
}

section.heroArea .logoArea h6 {
	margin-left: 1em;
}

section.heroArea .logoArea ul {
	max-width: 400px;
	margin-top: 1em;
}


section.ribbonArea {
	background-color: rgba(69,191,124,1);
}

section.ribbonArea h5 span {
	font-family: 'Poppins', sans-serif;
	color: rgb(1, 38, 56);
	font-size: 24px;
	margin-left: 3px;
}

section.testiArea,
section.testiArea div.slider-reviews,
section.clientArea {
	overflow-x: hidden;
}


section.workArea {
	background-color: #ecf5f8;
	position: relative;
}
section.workArea:before {
	content: "";
	position: absolute;
	top: 140px;
	margin: auto;
	left: 50%;
	transform: translateX(-50%);
	background-color: #012638;
	width: 4px;
	height: 78%;
	border-radius: 10px;
}
section.workArea h4 {
	font-size: 26px;
}
section.workArea .row:nth-child(2){
	position: relative;
}

section.workArea .row .col-lg-6:nth-child(2n + 2) .picBox {
	margin-top: 80px;
}

section.workArea .row .col-lg-6:nth-child(2) .picBox {
	margin-top: 200px;
}

section.workArea .row:nth-child(2):before,
section.workArea .row:nth-child(2):after {
	content: "";
	position: absolute;
	top: 0px;
	margin: auto;
	left: 50%;
	transform: translateX(-50%);
	background-color: #012638;
	width: 20px;
	height: 20px;
	-webkit-border-radius: 100px;
	border-radius: 100px;
}
section.workArea .row:nth-child(2):after {
	top: auto;
	bottom: 75px;
}

section.workArea .picBox {
	max-width: 350px;
	width: 100%;
	height: 310px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	padding: 1em;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
}

section.workArea .col-lg-6 .picBox {
	margin-top: 0px;
}

section.workArea .col-lg-6:nth-child(3) .picBox,
section.workArea .col-lg-6:nth-child(5) .picBox,
section.workArea .col-lg-6:nth-child(7) .picBox {
	margin-top: -120px;
}

section.workArea .picBox .num {
	position: absolute;
	width: 100px;
	height: 100px;
	-webkit-border-radius: 100px;
	border-radius: 100px;
}

section.workArea .picBox .num.bg-green {
	background-color: #45BF7C;
	top: 60px;
	right: -160px;
}

section.workArea .picBox .num.bg-green:before {
	background-color: #45BF7C;
	top: 50%;
	width: 100px;
	height: 5px;
	content: "";
	position: absolute;
	left: -80px;
}

section.workArea .picBox .num.bg-blue {
	background-color: #012638;
	top: 60px;
	left: -160px;
}

section.workArea .picBox .num.bg-blue:before {
	background-color: #012638;
	top: 50%;
	width: 100px;
	height: 5px;
	content: "";
	position: absolute;
	right: -80px;
}

section.workArea .picBox .num span {
	font-family: 'Poppins', sans-serif;
	position: absolute;
	top: 0;
	left: 0;
	width: 100px;
	height: 100px;
	font-style: normal;
	color: rgb(255, 255, 255);
	font-size: 40px;
	line-height: 100px;
}

section.workArea .picBox p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	color: rgb(1, 38, 56);
	font-size: 18px;
	line-height: 1.25;
	/*max-width: 250px;*/
	margin: 1em auto 0;
}


section.benifitArea h4 {
	font-family: 'Poppins', sans-serif;
	font-size: 26px;
	margin-bottom: 0.8em;
}

section.benifitArea h3 {
	font-family: 'Poppins', sans-serif;
	max-width: 900px;
	font-size: 38px;
	line-height: 1.1;
}

section.benifitArea h5 {
	font-family: 'Poppins', sans-serif;
	max-width: 200px;
	font-size: 22px;
}

section.programArea {
	background-color: #075f8a;
}

section.programArea .whiteBox {
	background-color: #FFFFFF;
	padding: 3em;
	height: 100%;
	-webkit-border-radius: 20px;
	border-radius: 20px;
}

section.programArea h3 {
	font-family: 'Poppins', sans-serif;
	font-size: 26px;
}

section.programArea h4 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	color: rgb(1, 38, 56);
	font-size: 38px;
	line-height: 1;
}

section.programArea p {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-style: normal;
	color: #113447;
	font-size: 20px;
	line-height: 1;
}

section.faqArea {
	background-color: #ecf5f8;
}

section.faqArea h4 {
	margin-bottom: 0.8em;
}

section.faqArea h3 {
	font-style: normal;
	color: rgb(1, 38, 56);
	font-size: 38px;
	line-height: 1;
}

section.faqArea p.text-desc  {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	color: rgb(1, 38, 56);
	line-height: 1;
	font-size: 22px;
	padding: 0 2em;
}


footer.footer {
	background-color: #075f8a;
}

footer.footer ul {
	max-width: 500px;
}

footer.footer ul li a,
footer.footer ul li a.disclaimer__button-as-link {
	color: #FFFFFF;
	cursor: pointer;
	font-weight: 400;
}
footer.footer ul li a:hover,
footer.footer ul li a.disclaimer__button-as-link:hover {
	color: rgb(69, 191, 124);
}
footer.footer ul li a,
footer.footer ul li a:hover,
footer.footer ul li a:focus {
	font-size: 13px;
	text-decoration: none;
}

footer.footer .text-disclaimer {
	font-family: 'Poppins', sans-serif;
	font-size: 11px;
	max-width: 1000px;
	margin: auto;
	line-height: 22px;
}

footer.footer .text-copy {
	font-style: normal;
	color: #FFFFFF;
	font-size: 13px;
	line-height: 22px;
	margin: 0;
}


/*  Thankyou Page */

.step-form-wrap {
	padding-top: 5em;
	padding-bottom: 5em;
	position: relative;
	background-color: rgba(236,245,248,1);
	border-top: 1px solid #45BF7C;
}

.step-form-wrap h3 {
    margin-top: -0em;
    margin-bottom: 3em;
    font-family: Poppins;
    font-weight: 700;
    font-style: normal;
    color: #075f8a;
    font-size: 35px;
    line-height: 1;
}

.step-form-wrap form#leadform {
    margin-top: 1em;
}

.step-form-wrap [id*="question"] {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
}

.step-form-wrap [id*="question"] {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
}

.step-form-wrap [id*="question3"] {
	max-width: 1000px;
}
.step-form-wrap [id*="question6"],
.step-form-wrap [id*="question7"] {
	max-width: 900px;
	width: 90%;
}

form#leadform .inner ul {
	list-style-type: none;
	width: 100% !important;
	text-align: center;
	display: flex;
	justify-content: center;
	display: -ms-flexbox;
	display: -webkit-flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 0;
}

form#leadform .inner h3.step-title {
	font-family: Poppins;
	font-weight: 500;
	font-size: 20px;
	line-height: 22px;
	color: #000000;
	text-align: center;
	margin-bottom: 1.5em;
}
div#question6 p#home_value,
div#question7 p#current_mortgage_balance {
	position: absolute;
	top: -40px;
	text-align: center;
	width: 100%;
	font-family: Poppins;
	font-weight: 500;
	font-size: 20px;
	line-height: 22px;
	color: #000000;
}
form#leadform .inner input,
form#leadform .inner input:focus {
	outline: 0 none;
	-webkit-box-shadow: 0 0 0;
	box-shadow: 0 0 0;
}
form#leadform .inner ul li input[type="radio"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	background-color: #ffffff;
	border: none;
	z-index: -9999;
	display: none;
}
form#leadform .inner ul li input[type="radio"] + label {
	cursor: pointer;
	display: block;
	text-align: center;
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	font-family: 'Poppins', sans-serif !important;
	background: #FFFFFF;
	border: 1px solid #45BF7C;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 300px;
	padding-bottom: 170px;
	position: relative;
}

form#leadform .inner ul li label.radio-button {
	margin-bottom: 15px;
}
form#leadform .inner ul li:nth-child(2n) label.radio-button {
	margin-left: 15px;
}
form#leadform .inner ul li input[type="radio"]:checked + label {
	background-color: #45BF7C;
	color: #FFFFFF;
	border-color: #45BF7C;
}
form#leadform .inner ul li input[type="radio"]:not(:checked) + label {
	background-color: #FFFFFF;
	color: #212529;
	border-color: #45BF7C;
}
form#leadform .inner ul li input[type="radio"] + label span {
	position: absolute;
	top: 70%;
	left: 0;
	right: 0;
	margin: auto;
	-webkit-transform: translate(0,-50%);
	transform: translate(0,-50%);
	max-width: 250px;
	font-size: 16px;
	font-weight: 700;
}

form#leadform .inner input.form-step__btn,
form#leadform .inner button.step-button {
	margin: 0 auto;
	display: block;
	color: #FFFFFF;
	background-color: #45BF7C;
	border: 0 none;
	border-radius: 4px;
	padding: 0.5em 1em;
	margin-top: 1em;
	width: 100%;
    max-width: 220px;
    height: 60px;
    border-radius: 100px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}
form#leadform .inner input.form-step__btn:hover,
form#leadform .inner button.step-button:hover {
	background-color: rgba(43,172,93,1);
}

form#leadform .inner #question3 ul li + li label.radio-button {
	margin-left: 15px;
}
form#leadform .inner #question3 ul li:nth-child(5) label.radio-button {
	margin-left: 0px;
}
form#leadform .inner #question3 ul li input[type="radio"] + label {
	width: 230px;
}
/*form#leadform .inner #question3 ul li input[type="radio"] + label span {
	max-width: 148px;
	font-size: 14px;
}*/


div#question6,
div#question7 {
	position: relative;
}
form#leadform .inner span.MuiSlider-root:before {
	content: "$100K";
	left: 0;
	bottom: -25px;
	position: absolute;
	color: #000000;
	font-size: 18px;
	font-weight: 400;
}
form#leadform .inner span.MuiSlider-root:after {
	content: "$1.5M+";
	right: 0;
	bottom: -25px;
	position: absolute;
	color: #000000;
	font-size: 18px;
	font-weight: 400;
}
form#leadform .inner span.MuiSlider-root {
	width: 100% !important;
	height: 13px !important;
	left: 50%;
	top: 60px;
	transform: translate(-50%);
	-webkit-transform: translate(-50%);
	-moz-transform: translate(-50%);
	-o-transform: translate(-50%);
	-webkit-border-radius: 4px;
	border-radius: 4px;
}
div#question6 span.MuiSlider-root + p,
div#question7 span.MuiSlider-root + p {
	position: relative;
	margin: -30px 0 3em;
	left: 0;
	text-align: center;
	width: 100%;
	font-weight: 400;
	font-size: 30px;
	color: #45BF7C;
	line-height: 30px;
}
form#leadform .inner span.MuiSlider-rail {
	background-color: #45BF7C;
}
form#leadform .inner span.MuiSlider-track {
	background-color: #45BF7C;
	border: 0 none;
}
form#leadform .inner span.MuiSlider-thumb {
	background-color: #45BF7C;
}
form#leadform .inner span.MuiSlider-thumb span {
	display: none;
}

div#question9 .input-wrap label,
div#question10 .input-wrap label,
div#question11 .input-wrap label {
	max-width: 350px;
	margin: 1em auto 0.3em;
	width: 100%;
	display: block;
	font-size: 20px;
	line-height: 22px;
	font-weight: 500;
}
div#question9 .input-wrap label + input,
div#question10 .input-wrap label + input,
div#question11 .input-wrap label + input {
	max-width: 350px;
	line-height: 50px;
	height: 50px;
	margin: auto;
	width: 100%;
	border: 1px solid #45BF7C;
}
.input-wrap .error-message {
	max-width: 350px;
	margin: 1em auto 0.3em;
	width: 100%;
	display: block;
	color: red;
	font-size: 12px;
	margin-top: 5px;
}

.radio-list li input + label:before {
	position:absolute;
	top: 50px;
	left:0;
	right:0;
	margin:auto;
	background-position:center center;
	background-repeat:no-repeat;
	content:"";
	width:100%;
	height:60px;
	-webkit-transform:translate(0,-40%);
	transform:translate(0,-40%);
    /*-webkit-filter: invert(0) brightness(1) hue-rotate(0deg);
    filter: invert(0) brightness(1) hue-rotate(0deg);*/
    -webkit-filter:invert(0) brightness(1) hue-rotate(100deg) saturate(100%);
    filter:invert(0) brightness(1) hue-rotate(100deg) saturate(100%);
    background-size: contain;
}

.radio-list li input:checked + label:before {
	-webkit-filter: invert(1) brightness(100) hue-rotate(0);
	filter: invert(1) brightness(100) hue-rotate(0);
}

.radio-list li input:not(:checked) + label:before {
	-webkit-filter: invert(0) brightness(1) hue-rotate(100deg) saturate(100%);
	filter: invert(0) brightness(1) hue-rotate(100deg) saturate(100%);
}


div#question1 li:nth-of-type(1) label:before {
	background-image:url(../../assets/img/needwork.png);
	background-size: 60px auto;
}
div#question1 li:nth-of-type(2) label:before {
	background-image:url(../../assets/img/fair.png);
	background-size: 60px auto;
}  
div#question1 li:nth-of-type(3) label:before {
	background-image:url(../../assets/img/poornew.png);
	background-size: 60px auto;
}
div#question1 li:nth-of-type(4) label:before{
	background-image:url(../../assets/img/excellentnew.png);
	background-size: 60px auto;
}


div#question2 li:nth-of-type(1) label:before{
	background-image:url(../../assets/img/home-icon.png);
}
div#question2 li:nth-of-type(2) label:before{
	background-image:url(../../assets/img/tenant.png);
}
div#question2 li:nth-of-type(3) label:before{
	background-image:url(../../assets/img/house.png);
}
div#question2 li:nth-of-type(4) label:before{
	background-image:url(../../assets/img/land.png);
}


div#question3 li:nth-of-type(1) label:before{
	background-image:url(../../assets/img/payment-method.png);
}
div#question3 li:nth-of-type(2) label:before{
	background-image:url(../../assets/img/portfolio.png);
}
div#question3 li:nth-of-type(3) label:before{
	background-image:url(../../assets/img/money.png);
}
div#question3 li:nth-of-type(4) label:before{
	background-image:url(../../assets/img/renovation.png);
}
div#question3 li:nth-of-type(5) label:before{
	background-image:url(../../assets/img/downsizing.png);
}
div#question3 li:nth-of-type(6) label:before{
	background-image:url(../../assets/img/home-value.png);
}


div#question4 li:nth-of-type(1) label:before{
	background-image:url(../../assets/img/asap.png);
}
div#question4 li:nth-of-type(2) label:before{
	background-image:url(../../assets/img/calendar-icon.png);
}
div#question4 li:nth-of-type(3) label:before{
	background-image:url(../../assets/img/appointment.png);
}
div#question4 li:nth-of-type(4) label:before{
	background-image:url(../../assets/img/annual.png);
}


div#question5 li:nth-of-type(1) label:before{
	background-image:url(../../assets/img/single-family.png);
}
div#question5 li:nth-of-type(2) label:before{
	background-image:url(../../assets/img/multi-family.png);
}
div#question5 li:nth-of-type(2) label:before{
	background-image:url(../../assets/img/condominium.png);
}
div#question5 li:nth-of-type(3) label:before{
	background-image:url(../../assets/img/mobile-home.png);
}
div#question5 li:nth-of-type(4) label:before{
	background-image:url(../../assets/img/townhouse.png);
}


div#question8 li label:before {
	display: none;
}

div#question8 li input[value="No"] + label {
	outline: 3px solid #45bf7c;
	outline-offset: -4px;
}

form#leadform .inner div#question8 ul li input[type="radio"] + label span {
	top: 50%;
	font-size: 24px;
}

form#leadform .inner .errors {
	display: none;
}
form#leadform .inner input.error{
	border: 1px solid #F00 !important;
}


/*  Progress Bar */

.stepBar {
	position: relative;
	margin: 0em auto 3em;
	font-size: 0;
	color: transparent;
	top: -50px;
	max-width: 605px;
	width: 100%;
}
.stepBar:after {
	background-color: #45BF7C !important;
	content: "";
	color: transparent;
	position: absolute;
	left: 0px;
	width: 100%;
	height: 20px;
	-webkit-border-radius: 20px;
	border-radius: 20px;
}
.stepBar[class*=step]:before{
	background-color: rgba(255, 255, 255, 0.35) !important;
	content: "";
	color: transparent;
	position: absolute;
	left: 0px;
	width: 100%;
	height: 21px;
	-webkit-border-radius: 20px;
	border-radius: 20px;
	z-index: 9;
}
.stepBar.step1:before {
	max-width: calc(100.83px * 1) !important;
}
.stepBar.step2:before {
	max-width: calc(100.83px * 2) !important;
}
.stepBar.step3:before {
	max-width: calc(100.83px * 3) !important;
}
.stepBar.step4:before {
	max-width: calc(100.83px * 4) !important;
}
.stepBar.step5:before {
	max-width: calc(100.83px * 5) !important;
}
.stepBar.step6:before {
	max-width: calc(100.83px * 6) !important;
}

.zip-code-error{
	margin-left: 20px;
}

.zip-form-error{
	border: solid 3px red;
}

div[role="dialog"] {
	padding: 0 !important;
}
.modal-dialog { max-width: 1000px !important; width: 90% !important;}
.modal-dialog .modal-content { width: 100% !important }
.modal-dialog .modal-content .modal-header { background-color: rgba(7,95,138,1);}
.modal-dialog .modal-content .modal-header .modal-title.h4 { color: #FFFFFF;}
.modal-dialog .modal-content .modal-header button.btn-close { color: #FFF !important; background-color: #FFF !important;}

/*FOR PLACEHOLDER TEXT*/
::-webkit-input-placeholder { 
	color: #113447;
	opacity: 0.6 !important;
}
:-moz-placeholder { 
	color: #113447; 
	opacity: 0.6 !important;
}

::-moz-placeholder {  
	color: #113447; 
	opacity: 0.6 !important;
}

:-ms-input-placeholder { 
	color: #113447; 
	opacity: 0.6 !important;
}

.how-soon-subtext{
	top: 40px!important;
	font-style: italic;
	font-size: 13px!important;
	line-height: 20px!important;
	color: #45BF7C;
}

form#leadform .inner ul li input[type="radio"]:checked + label .how-soon-subtext,
form#leadform .inner ul li input[type="radio"] + label:hover .how-soon-subtext{
		background-color: #45BF7C;
		color: #FFFFFF!important;
		border-color: #45BF7C;
	}

.street-info-label{
	font-size: 12px !important;
	margin-top: 5px !important;
}


@media(min-width: 768px){

	form#leadform .inner ul li input[type="radio"]:checked + label,
	form#leadform .inner ul li input[type="radio"] + label:hover{
		background-color: #45BF7C;
		color: #FFFFFF!important;
		border-color: #45BF7C;
	}
	.radio-list li input + label:hover:before{
		-webkit-filter: invert(1) brightness(100) hue-rotate(0);
		filter: invert(1) brightness(100) hue-rotate(0);
	}

}

@media(min-width: 992px){

	section.programArea .whiteBox {
		padding: 4em 3em 7em;
	}

}


@media(max-width: 1199px){

	section.workArea .picBox .num {
		width: 80px;
		height: 80px;
	}
	section.workArea .picBox .num.bg-green {
		right: -105px;
	}
	section.workArea .picBox .num.bg-green:before {
		left: -60px;
	}
	section.workArea .picBox .num.bg-blue {
		left: -105px;
	}
	section.workArea .picBox .num.bg-blue:before {
		left: 40px;
	}
	section.workArea .picBox .num span {
		width: 80px;
		height: 80px;
		font-size: 30px;
		line-height: 80px;
	}

}

@media(max-width: 991px){
	
	.heroContent {
		margin-top: 0 !important;
		padding-top: 0 !important;
	}
	section.heroArea .heroContent h1 {
		margin-top: 1em !important;
		padding-top: 0 !important;
	}

	section.ribbonArea h5 span {
		font-size: 20px;
		margin-left: 0;
		display: block;
	}

	section.workArea:before,
	section.workArea .row:nth-child(2):before, 
	section.workArea .row:nth-child(2):after {
		display: none;
	}
	section.workArea .picBox .num.bg-green:before,
	section.workArea .picBox .num.bg-blue:before {
		display: none;
	}
	section.workArea .picBox .num.bg-green,
	section.workArea .picBox .num.bg-blue {
		right: 0;
		left: 0;
		margin: auto;
		top: -35px;
	}
	section.workArea .row .col-lg-6 .picBox {
		margin-top: 20px;
	}
	section.workArea .row .col-lg-6 + .col-lg-6 .picBox,
	section.workArea .row .col-lg-6:nth-child(2) .picBox {
		margin-top: 70px;
	}

}

@media(max-width: 767px){

	section.heroArea .heroContent h1 {
		font-size: 40px;
		text-align: center;
	}
	section.heroArea .heroContent h2 {
		font-size: 22px;
		text-align: center;
		margin: 1.2em 0 3em !important;
	}
	section.heroArea .heroContent form#form {
		background-color: transparent;
		flex-direction: column;
	}
	section.heroArea .heroContent form#form input#address {
		height: 70px;
		line-height: 70px;
		border-radius: 100px;
		max-width: 300px;
		margin: 0 auto 1.2em;
		font-size: 16px;
	}
	section.heroArea .logoArea {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.programArea .col-lg-6 + .col-lg-6 {
		margin-top: 1.5em;
	}
	section.benifitArea h3 {
		font-size: 27px;
	}
	section.programArea .whiteBox img {
		max-width: 100px;
	}
	section.programArea .whiteBox h4 {
		font-size: 30px;
		max-width: 200px;
		margin: auto;
	}
	section.programArea .whiteBox p {
		font-size: 18px;
		line-height: 1.25;
	}
	section.faqArea h3 {
		margin-bottom: 0.5em !important;
	}
	section.faqArea p.text-desc {
		line-height: 1.25;
		font-size: 18px;
		margin-top: 0 !important;
	}
	form#leadform .inner h3.step-title {
		font-size: 18px;
	}
	form#leadform .inner ul li input[type="radio"] + label {
		width: 140px;
		padding-bottom: 130px;
	}
	form#leadform .inner ul li input[type="radio"] + label:before {
		background-size: 40px auto;
		top: 30px;
	}
	form#leadform .inner #question4 ul li:first-child input[type="radio"] + label:before,
	form#leadform .inner #question5 ul li:nth-child(2) input[type="radio"] + label:before {
	    top: 35px;
	}
	form#leadform .inner ul li input[type="radio"] + label span {
		font-size: 12px;
	}
	form#leadform .inner #question3 ul li + li label.radio-button {
		margin-left: 15px!important;
	}
	form#leadform .inner #question3 ul li:nth-child(3) label.radio-button,
	form#leadform .inner #question3 ul li:nth-child(5) label.radio-button,
	form#leadform .inner #question3 ul li:last-child label.radio-button {
		margin-left: 0px;
	}
	form#leadform .inner #question3 ul li input[type="radio"] + label {
		width: 140px;
		margin-left: 15px;
	}
	form#leadform .inner #question3 ul li input[type="radio"] + label span {
	    max-width: 125px;
	    font-size: 12px;
	}
	form#leadform .inner #question5 ul li input[type="radio"] + label span {
		font-size: 11px;
	}
	div#question6 span.MuiSlider-root + p,
	div#question7 span.MuiSlider-root + p {
		font-size: 25px;
	}
	.stepBar {
		max-width: 300px;
	}
	.stepBar.step1:before {
		max-width: calc(50px * 1) !important;
	}
	.stepBar.step2:before {
		max-width: calc(50px * 2) !important;
	}
	.stepBar.step3:before {
		max-width: calc(50px * 3) !important;
	}
	.stepBar.step4:before {
		max-width: calc(50px * 4) !important;
	}
	.stepBar.step5:before {
		max-width: calc(50px * 5) !important;
	}
	.stepBar.step6:before {
		max-width: calc(50px * 6) !important;
	}

}

@media(max-width: 568px){

	section.heroArea .heroContent h1 {
		font-size: 30px;
	}
	section.ribbonArea h5 span {
		font-family: 'Poppins', sans-serif;
		color: rgb(1, 38, 56);
		font-size: 20px;
		margin-left: 0px;
		display: block;
	}
	.how-soon-subtext{
		top: 30px!important;
		font-size: 8.5px!important;
		line-height: 20px!important;
	}
}

@media(max-width: 480px){
	
	header .ctaGreen.ctaSml {
		white-space: nowrap;
		font-size: 12px;
	}
	section.heroArea .heroContent h2 {
		font-size: 17px;
	}
	section.heroArea form .ctaGreen {
		font-size: 16px;
		padding: 1.2em 0;
		width: 100%;
		max-width: 300px;
	}
	section.ribbonArea h5,
	section.ribbonArea h5 span {
		font-size: 16px;
	}
	section.testiArea .row .col-lg-12 h3.text-green,
	section.workArea h4,
	section.benifitArea h4,
	section.programArea h3,
	section.faqArea h4 {
		font-size: 23px;
		max-width: 230px;
		margin: 0 auto 0.8em !important;
	}

	section.programArea .whiteBox h4 {
		font-size: 26px;
		max-width: 150px;
	}
	
	section.heroArea .heroContent h1,
	section.benifitArea h3 {
		font-size: 26px;
	}

	section.benifitArea h5 {
		max-width: 200px;
		font-size: 18px;
	}
	section.faqArea h3 {
		font-size: 30px;
	}

	form#leadform .inner input.form-step__btn,
	form#leadform .inner button.step-button {
		font-size: 18px;
	}


}

@media(max-width: 320px){

	header .ctaGreen.ctaSml {
		font-size: 10px;
	}

}