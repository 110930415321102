#thankYouFirstSection {
    display: block;
    background: rgba(236,245,248,1);
    border-style: solid none none none;
    border-width: 1px;
    border-color: #45BF7C;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 100%;
    position: relative;
    padding: 5em 0;
}
#thankYouFirstSection .col-lg-12.text-center {
    margin-left: auto;
    margin-right: auto;
    width: 1180px;
}
#thankYouFirstSection .col-lg-12.text-center span{
    font-weight: 700;
    font-style: normal;
    color: rgb(7, 95, 138);
    font-size: 40px;
    line-height: 1.3;
}

@media(max-width: 991px){

    #thankYouFirstSection .col-lg-12.text-center span{
        font-size: 35px;
    }

}

@media(max-width: 767px){
    #thankYouFirstSection .col-lg-12.text-center span{
        font-size: 32px;
    }

}

@media(max-width: 568px){
    #thankYouFirstSection .col-lg-12.text-center span{
        font-size: 26px;
    }

}

